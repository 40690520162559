import React, { useMemo } from 'react';
import Link from 'next/link';
import { useSession, signOut } from 'next-auth/react';
import { HomeIcon, CalendarIcon, ShoppingBagIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';

export default function TopNavBar() {
  const { data: session } = useSession();

  // Cache the icons
  const icons = useMemo(() => ({
    dashboard: (
      <svg className="h-5 w-5 text-red-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 6C3 4.34315 4.34315 3 6 3H18C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3 9H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 21V9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    ),
    home: <HomeIcon className="h-5 w-5" />,
    calendar: <CalendarIcon className="h-5 w-5" />,
    order: <ShoppingBagIcon className="h-5 w-5" />,
    manage: (
      <svg className="h-5 w-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 5H7C5.89543 5 5 5.89543 5 7V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V7C19 5.89543 18.1046 5 17 5H15M9 5C9 6.10457 9.89543 7 11 7H13C14.1046 7 15 6.10457 15 5M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5M12 12H15M12 16H15M9 12H9.01M9 16H9.01" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    ),
    settings: <Cog6ToothIcon className="h-5 w-5" />,
  }), []);

  return (
    <nav className="bg-white shadow">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex-shrink-0 flex items-center">
            {icons.dashboard}
            <span className="text-xl font-bold text-gray-900 ml-2">Dashboard</span>
          </div>
          <div className="hidden sm:flex sm:space-x-6">
            <Link href="/dashboard" className="text-gray-500 hover:text-gray-700 px-3 py-2 rounded-md text-sm font-medium flex flex-col items-center">
              {icons.home}
              <span className="mt-1 text-xs">Home</span>
            </Link>
            <Link href="/calendar" className="text-gray-500 hover:text-gray-700 px-3 py-2 rounded-md text-sm font-medium flex flex-col items-center">
              {icons.calendar}
              <span className="mt-1 text-xs">Calendar</span>
            </Link>
            <Link href="/order" className="text-gray-500 hover:text-gray-700 px-3 py-2 rounded-md text-sm font-medium flex flex-col items-center">
              {icons.order}
              <span className="mt-1 text-xs">Order</span>
            </Link>
            <Link href="/manage" className="text-gray-500 hover:text-gray-700 px-3 py-2 rounded-md text-sm font-medium flex flex-col items-center">
              {icons.manage}
              <span className="mt-1 text-xs">Manage</span>
            </Link>
            <Link href="/settings" className="text-gray-500 hover:text-gray-700 px-3 py-2 rounded-md text-sm font-medium flex flex-col items-center">
              {icons.settings}
              <span className="mt-1 text-xs">Settings</span>
            </Link>
          </div>
          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-500">{session?.user?.email}</span>
            <button
              onClick={() => signOut()}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}
